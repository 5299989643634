<template>
  <v-autocomplete
    v-if="headerObj.type && headerObj.type.split('-')[0] === 'ForeignKey'"
    :label="headerObj.text"
    :items="items"
    item-text="name"
    item-value="id"
    return-object
    v-model="newVal"
    :disabled="headerObj['can edit'] == ''"
  />
  <v-autocomplete
    v-else-if="headerObj['choices'] && headerObj['choices'].length > 0"
    :label="headerObj.text"
    :items="items"
    v-model="newVal"
    :disabled="headerObj['can edit'] == ''"
  />
  <v-switch
    v-else-if="headerObj.type && headerObj.type === 'BooleanField'"
    v-model="newVal"
    :label="headerObj.text"
    :disabled="headerObj['can edit'] === ''"
  ></v-switch>

  <date-field
    v-else-if="headerObj.type && headerObj.type === 'datetime'"
    v-model="newVal"
    :label="headerObj.text"
    :disabled="headerObj['can edit'] === ''"
  />
  <v-text-field
    v-else-if="
      (headerObj.type && headerObj.type === 'FloatField') ||
        (headerObj.type && headerObj.type === 'IntegerField')
    "
    v-model="newVal"
    type="number"
    :label="headerObj.text"
    :disabled="headerObj['can edit'] === ''"
  />
  <v-text-field
    v-else
    :label="headerObj.text"
    hide-details="auto"
    class="mb-4"
    v-model="newVal"
    :rules="headerObj.blank === false ? rules.name : []"
    :disabled="headerObj['can edit'] === ''"
    @blur="$emit('blur')"
  />
</template>

<script>
import DateField from "@/components/dateField";
import api from "@/api";
import {mapGetters} from "vuex";

export default {
  name: "DifferentFields",
  components: { DateField },
  props: {
    headerObj: {
      type: Object,
      default: () => {}
    },
    value: {
      default: null
    },
    order: {
      type: [String, Number, null],
      default: null
    }
  },
  data() {
    return {
      items: [{ text: "lb", value: 1 }],
      rules: {
        name: [v => !!v || "This field is required"],
        langth: [
          v => (v && v.length <= 10) || "Name must be less than 10 characters"
        ]
      }
    };
  },
  computed: {
    ...mapGetters(["User"]),
    newVal: {
      get() {
        return this.value;
      },
      set(newVal) {
        if (this.headerObj.type && this.headerObj.type === "FloatField") {
          this.$emit("input", parseInt(newVal, 10));
        } else this.$emit("input", newVal);
        // if (this.headerObj.type && this.headerObj.type === "FloatField") {
        // }
      }
    }
  },

  mounted() {
    if (this.headerObj.type && this.headerObj.type.split("-").length === 2) {
      let id;
      if (
        this.headerObj.type == "ForeignKey-WeightBox" ||
        this.headerObj.type == "ForeignKey-Vehicle" ||
        this.headerObj.type == "ForeignKey-Drivers"
      ) {
        this.order ? (id = this.order) : (id = 0);
      } else if (this.headerObj.value === "cargo") {
        id = this.User.companyId
      } else id = null;
      api
        .getlists({
          args: {
            path: `${this.headerObj.type.split("-")[1]}${
              id || id === 0 ? "/" + id : ""
            }`
          }
        })
        .then(responce => {
          if (responce) {
            this.items = responce.results?.data;
          }
        });
    }
    if (this.headerObj.type === "BooleanField" && this.value === null) {
      this.newVal = false;
    }
    if (this.headerObj["choices"] && this.headerObj["choices"].length > 0) {
      this.items = this.headerObj["choices"].map(item => {
        let key = Object.keys(item)[0];
        return item[key];
      });
    }
  }
};
</script>

<style scoped></style>

<template>
  <v-menu
    ref="menu1"
    v-model="menu1"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="dateValue"
        readonly
        :label="label"
        hint="MM/DD/YYYY format"
        persistent-hint
        prepend-icon="event"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title @input="chusenDate"></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'dateField',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
    },
  },
  data() {
    return {
      menu1: false,
    }
  },
  computed: {
    dateValue() {
      return !this.value
        ? this.convertToString(new Date())
        : this.convertToDate(this.value)
    },
    date: {
      get() {
        return this.dateValue
      },
      set(value) {
        this.$emit('input', this.convertToTimeStamp(value))
      },
    },
  },
  methods: {
    chusenDate() {
      this.menu1 = false
    },
    convertToString(val) {
      return val.toISOString().substring(0, 10)
    },
    convertToDate(val) {
      let date = new Date(val * 1000)
      return date.toISOString().substring(0, 10)
    },
    convertToTimeStamp(val) {
      const toDate = new Date(val)
      return toDate.getTime() / 1000
    },
  },
}
</script>

<style scoped></style>
